<template>
  <div class="home">
    <div class="homeBanner">
      <div class="WebMain">
        <div class="text wow fadeInLeft">
          <p class="p30">专为外汇券商打造的
</p>
          <p class="p30">智能化全流程解决方案</p>
          <div>
            <!-- <a href="http://demo.tradewisesoftware.com" target="_blank" rel="noopener noreferrer"></a> -->
            <span class="p22" @click="toDemo">
              <a href="http://demo.tradewisesoftware.com" target="_blank" rel="noopener noreferrer">系统演示</a>
            </span>
          </div>
        </div>
        <div class="img">
          <img src="../../assets/img/index/样机-min.png" alt="" />
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="crm">
        <div class="WebMain">
          <div class="title">
            <p class="p24">TradeWise CRM</p>
            <p></p>
          </div>

          <p class="p16">
            TradeWise CRM系统可以帮助外汇券商进行客户信息管理、客户维系和客户服务等方面的工作。通过TradeWise CRM系统，外汇券商可以收集客户信息和交易记录并将其整合，同时，CRM系统可以帮助外汇券商进行个性化营销，提供有针对性的服务和支持，从而提高客户的满意度和忠诚度。
          </p>
          <img class="wow fadeInLeft" src="../../assets/img/index/样机-2-min.png" alt="" />
        </div>
      </div>
      <div class="about">
        <div class="left">
          <img class="wow fadeInLeft" src="../../assets/img/index/Workspace-min.png" alt="" />
        </div>
        <div class="right">
          <div class="title">
            <p class="p24">公司介绍</p>
            <p></p>
          </div>
          <p class="p14">
            TradeWise是一家专注于为外汇经纪商提供CRM和后台管理系统的软件公司。我们致力于提供高质量的软件解决方案，以满足我们客户的业务需求。作为一家领先的软件公司，我们拥有优秀的技术团队和专业的客户服务团队，能够帮助经纪商高效地管理客户、组织和员工，从而实现快速的业务增长和稳定的扩张。
          </p>
        </div>
      </div>
      <div class="partner">
        <div class="WebMain">
          <div class="title">
            <p class="p24">合作伙伴</p>
            <p></p>
          </div>
          <div class="center">
            <div class="item" v-for="item in imgList" :key="item.id">
              <img
                :src="require(`../../assets/img/index/${item.path}`)"
                alt=""
              />
            </div>
          </div>
          <div class="bottom">
            <div class="item" v-for="item in imgList2" :key="item.id">
              <img
                :src="require(`../../assets/img/index/${item.path}`)"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgList: [
        { id: 1, path: "icon (1).png" },
        { id: 2, path: "icon (2).png" },
        { id: 3, path: "icon (3).png" },
        { id: 4, path: "icon (4).png" },
        { id: 5, path: "icon (5).png" },
      ],
      imgList2: [
        { id: 1, path: "icon (1).png" },
        { id: 2, path: "icon (2).png" },
        { id: 3, path: "icon (3).png" },
        { id: 4, path: "icon (4).png" },
        { id: 5, path: "icon (1).png" },
        { id: 6, path: "icon (2).png" },
      ],
    };
  },
  methods:{
    toDemo() {
      window.href = 'http://demo.tradewisesoftware.com'
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  .homeBanner {
    min-height: 900px;
    box-sizing: border-box;
    background-size: 100% 100%;
    // background: url("../../assets/img/index/背景备份-min.png");
    padding-top: 90px;
    // color: #fff;
    .WebMain {
      // position: relative;
      display: flex;
      flex-wrap: wrap;
      .text {
        padding-top: 320px;
        p {
          
font-family: Barlow-BoldItalic, Barlow;
          // color: #ffffff;
          line-height: 50px;
          letter-spacing: 1px;
          // text-shadow: 0px 4px 4px rgba(99, 149, 177, 0.69);
        }
        div {
          margin-top: 50px;
          width: 200px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          background: var(--custom-color);
          box-shadow: 2px 6px 6px 0px rgba(144, 210, 243, 0.26);
          border-radius: 8px;
          cursor: pointer;
          span {
            font-weight: 600;
            // line-height: 50px;
            // a{
            //   line-height: 54px;
            // }
          }
        }
      }
      .img {
        // width: 500px;
        position: relative;
        img {
          position: absolute;
          left: 0;
          top: 0px;
          width: 908px;
        }
      }
    }
  }
  .bottom {
    .crm {
      padding-top: 81px;
      padding-bottom: 40px;
      text-align: center;
      .title {
        display: inline-block;
        margin: 0 auto;
        margin-bottom: 28px;

        font-weight: bold;
        p {
          line-height: 26px;
          &:last-child {
            margin-top: 15px;
            width: 100px;
            height: 2px;
            background: var(--custom-color);
          }
        }
      }
      p {
        line-height: 24px;
        // text-align: center;
      }
      img {
        // width: 738px;
        height: 615px;
        margin-top: 17px;
      }
    }
    .about {
      padding: 125px 0;

      background: url("../../assets/img/index/背景-min.png");
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      flex-wrap: wrap;
      // align-items: center;
      .left {
        position: relative;
        width: 860px;
        img {
          width: 100%;
          position: absolute;
          top: -32px;
          left: 0;
        }
      }
      .right {
        margin-left: 60px;
        width: 880px;
        height: 350px;
        background: #ffffff;
        border-radius: 12px;
        opacity: 0.95;
        box-shadow: 0px 0px 10px 0px rgba(43,45,51,0.2);

        color: #333333;
        padding: 62px 60px;
        padding-right: 210px;
        box-sizing: border-box;
        p {
          font-family: ArialHebrew;
          color: #333333;
          line-height: 28px;
        }
        .title {
          margin-bottom: 20px;
          p {
            font-weight: bold;
            color: #1a1a1a;
            line-height: 22px;
            &:last-child {
              width: 60px;
              height: 2px;
              background: #ff8e2f;
              margin-top: 16px;
            }
          }
        }
      }
    }
    .partner {
      padding-top: 98px;
      padding-bottom: 52px;
      text-align: center;
      .title {
        display: inline-block;
        margin-bottom: 50px;
        p {
          line-height: 26px;
          font-weight: bold;
          &:last-child {
            width: 60px;
            height: 2px;
            background: var(--custom-color);
            margin-top: 20px;
          }
        }
      }
      .item {
        width: 200px;
        height: 87px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        &:hover {
          transition: all 0.5s;
          background: #ffffff;
          box-shadow: 0px 0px 11px 0px rgba(67, 67, 67, 0.48);
          
        }
        img {
          width: 140px;
        }
      }
      .center,
      .bottom {
        display: flex;
        justify-content: space-between;
      }
      .center {
        padding: 0 40px;
        margin-bottom: 30px;
      }
    }
  }
}
@media screen and (min-width: 800px) and (max-width: 1700px) {
  .home .homeBanner .WebMain .img img {
    width: 690px;
  }
  .home .bottom .partner .center,
      .bottom {
        flex-wrap: wrap;
        justify-content: space-around;
      }
}
@media screen and (max-width: 800px) {
  .home {
    .homeBanner {
      min-height: 800px;
      .WebMain {
        position: relative;
      .text {
        padding-top: 0;
        position: absolute;
        top: 320px;
        margin-left: 30px;
        div{
          // margin-top: 20px;
        }
      }
      .img {
        width: 100%;
        position: absolute;
        top: 0;
        img {
          width: 100%;
        }
      }
    }
    }
    .bottom .crm img {
      height: auto;
      width: 100%;
    }
    .bottom .about {
      .left {
        height: 350px;
      }
      .right {
        margin: 0;
        padding: 20px;
        height: auto;
      }
    }
    .bottom .partner{
      padding-top: 0;
    }
  
    .bottom .partner .center,
    .bottom .partner .bottom {
      padding: 0;
        flex-wrap: wrap;
        .item{
          width: 100px;
          img{
            width: 100%;
          }
        }
        // width: 100%;
        // flex-wrap: wrap;
        // justify-content: center;
        // padding: 0;
        // text-align: center;
        // .item{
        //   width: 100% !important;
        // }
      }
      .bottom .partner .center{
        margin-bottom: 0;
      .item{
        &:first-child, &:nth-child(2){
          width: 150px;
          img{
            width: 100px;
          }
        }
      }
    }

  }
}
</style>